function HintIcon() {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="10" stroke="#062c40" strokeWidth="1" fill="none" />
    <text x="12" y="17" textAnchor="middle" fill="#062c40">i</text>
  </svg>
  )
}

export default HintIcon
