import { useAppSelector } from "../hooks"
import { useState, useEffect, memo, ReactNode } from 'react'
import classNames from 'classnames'

import { selectHintFor } from "../renderer/policiesSlice"
import { selectCurrentUser } from "../renderer/currentUserSlice"
import { usePageContext } from "../renderer/usePageContext"
import { usePostNewHintViewMutation } from "../services/backendApi"
import InViewWrapper from "../components/InViewWrapper"
import HintIcon from "../components/HintIcon"
import { renderMicroMarkdown, timeToRead } from "./Util"
import { usePoliciesLoaded } from "./Auth"

function renderTemplate(template, tokens, replaceMap) {
  let renderedHtml = template;

  (tokens || []).forEach((token) => {
    const substitute = replaceMap[token]
    const pattern = '{{' + token + '}}'

    if (substitute) {
      const text = (typeof substitute === 'string') ? substitute : substitute()

      renderedHtml = renderedHtml.replaceAll(pattern, text)
    } else {
      console.log(`%c Warning: token ${token} is absent from replaceMap.`, 'color: orange')

      const text = '<span class="no-token">' + pattern + '</span>'

      renderedHtml = renderedHtml.replaceAll(pattern, text)
    }
  })

  return({
    __html: renderMicroMarkdown(renderedHtml)
  })
}

interface ReplaceMapType {
  [string]: () => string | string
}

interface HintProps {
  hintKey: string
  className: string
  replaceMap: ReplaceMapType
  showCloseButton: boolean
}

interface HintWrapperProps extends HintProps {
  pageViewId: string
}

interface HintComponentProps {
  hint: ReactNode
  replaceMap: ReplaceMapType
  pageViewId: string
  className: string
  showCloseButton: boolean
}

function HintComponent({ hint, replaceMap, pageViewId, className, showCloseButton }: HintComponentProps) {
  const currentUser = useAppSelector(selectCurrentUser)
  const [reportedHintView, setReportedHintView] = useState(false)
  const [postNewHintView] = usePostNewHintViewMutation()
  const [isClosed, setIsClosed] = useState(true)

  useEffect(() => {
    setIsClosed(false)
  }, [])

  const { template, tokens, sid, already_seen:alreadySeen } = hint
  const html = renderTemplate(template, tokens, replaceMap)

  const onInView = async (closed) => {
    if (closed && !isReportingViews) {
      setIsClosed(true)
      return
    }

    try {
      const result = await postNewHintView({
        sid,
        pageViewId,
        closed
      })

      if (!result.error) {
        setReportedHintView(true)
        if (closed) {
          setIsClosed(true)
        }
      }
    } catch(err) {
      console.log(err)
      return false
    }
  }

  const isReportingViews = currentUser.id && !reportedHintView

  return(
    <InViewWrapper skipOnView={!isReportingViews} intervalTs={timeToRead(template) * 1.5} onInterval={onInView} as="aside" className={classNames(className || "hint", { closed: isClosed, seen: alreadySeen })}>
      { showCloseButton && <button className="close-hint" onClick={() => onInView(true)}>✕</button> }
      <div className="hint-content">
        <HintIcon />
        <div className="hint-text" dangerouslySetInnerHTML={html} />
      </div>
    </InViewWrapper>
  )
}

function arePropsEqual(oldProps, newProps) {
  return (oldProps.hint === newProps.hint &&
          oldProps.pageViewId === newProps.pageViewId &&
          oldProps.showCloseButton === newProps.showCloseButton &&
          oldProps.className === newProps.className)
}

const MemoizedHintComponent = memo(HintComponent, arePropsEqual)

function HintWrapper({ hintKey, replaceMap, className, showCloseButton, pageViewId }: HintWrapperProps) {
  const hint = useAppSelector((state) => selectHintFor(state, hintKey))
  if (!hint) {
    console.log(`%c Warning: backend didn't find the hint for key ${hintKey}.`, 'color: orange')
    return null
  }

  return <MemoizedHintComponent hint={hint} replaceMap={replaceMap} pageViewId={pageViewId} className={className} showCloseButton={showCloseButton} />
}

function Hint({ hintKey, replaceMap, className, showCloseButton }: HintProps) {
  const pageContext = usePageContext()
  const policiesLoaded = usePoliciesLoaded()
  const [showHint, setShowHint] = useState(false)

  useEffect(() => {
    if (!policiesLoaded) {
      return null
    }

    const { configParams } = pageContext.config
    if (!configParams) {
      console.log(`%c Warning: configParams were not loaded for this page.`, 'color: orange')
      return null
    }

    const hintKeys = configParams.hint_keys
    if (!hintKeys) {
      console.log(`%c Warning: configParams.hint_keys were not set for this page.`, 'color: orange')
      return null
    }

    if (!hintKeys.includes(hintKey)) {
      console.log(`%c Warning: config key ${hintKey} was not preloaded via configParams.`, 'color: orange')
      return null
    }

    setShowHint(true)
  }, [policiesLoaded])

  return showHint && <HintWrapper hintKey={hintKey} replaceMap={replaceMap} className={className} pageViewId={pageContext.pageViewId} showCloseButton={showCloseButton} />
}

export default Hint
